<template>
  <div class="operate-list">
    <el-button type="text" size="medium" @click.stop="onList()">操作日志</el-button>
    <!-- 编辑弹框 -->
    <el-dialog title="操作日志" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="50%">
      <!-- 表格 -->
      <el-table :data="tableData" border stripe :header-cell-style="{
        background: '#FAFAFA', color: '#000000'
      }">
        <el-table-column label="操作时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.addDt || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作人" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.name || "-" }}</span>
            <span>丨</span>
            <span>{{ scope.row.mobile || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作类型" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.operateTypeName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="内容">
          <template slot-scope="scope">
            <span>{{ scope.row.content || "-" }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { getOperateList } from "@/api/order/order"
export default {
  props: ['orderId'],
  data() {
    return {
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        size: 10,
        keyWord: "",//查询关键字
      },
      editShow: false, //弹框开关
    }
  },
  methods: {
    // 【请求】表格数据
    getOperateList() {
      let data = this.tableParams;
      data.orderId = this.orderId;
      getOperateList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data;
          this.total = res.data.total;
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getTableList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getTableList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】详情
    onList() {
      this.editShow = true;
      this.getOperateList();// 【请求】表格数据
    }
  }
}
</script>

<style lang="scss" scoped>
.operate-list {
  display: inline-flex;
  margin: 0 .05rem;
}
</style>